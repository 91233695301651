<template>
  <div style="background: #F0F8FF">
    <!--    头部-->
    <div style="display: flex; background-color: #fff; height: 60px; line-height: 60px; border-bottom: 1px solid #eee">
      <div style="width: 240px; display: flex; padding-left: 30px">
        <div style="width: 60px">
          <img src="../../assets/images.jpg" alt="" style="width: 40px; position: relative; top: 10px; right: 0">
        </div>
        <div style="flex: 1; font-size: 20px; color: #1E90FF; font-weight: bold">书籍漂流系统</div>
      </div>
      <div style="flex: 1">

        <el-menu :default-active="'1'" class="el-menu-demo" mode="horizontal" router>
          <el-menu-item index="/front/home">首页</el-menu-item>
          <el-menu-item index="/front/article">书友圈</el-menu-item>
          <el-menu-item index="/front/discuss">协会讨论</el-menu-item>
          <el-menu-item index="/front/myBook">我的书库</el-menu-item>
          <el-menu-item index="/front/myBookSelf">我的书架</el-menu-item>
        </el-menu>
      </div>
      <div style="width: auto">
        <div v-if="!user.username" style="text-align: right; padding-right: 30px">
          <el-button @click="$router.push('/login')">登录</el-button>
          <el-button @click="$router.push('/register')">注册</el-button>
        </div>
        <div v-else>
          <el-button @click="drawer = true" type="success" icon="el-icon-s-comment" plain style="margin-right: 10px">
            消息
          </el-button>
          <el-button @click="dialogFormVisible = true" type="" icon="el-icon-close-notification"
                     style="margin-right: 10px">公告
          </el-button>
          <el-dropdown style="width: auto; cursor: pointer; text-align: right">
            <div style="display: inline-block">
              <img :src="user.avatarUrl" alt=""
                   style="width: 50px; border-radius: 50%; position: relative; top: 10px;">
              <span>{{ user.nickname }}</span><i class="el-icon-arrow-down" style="margin-left: 5px"></i>
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center">
              <el-dropdown-item style="font-size: 14px; padding: 5px 0" v-if="user.role === 'ROLE_ADMIN'">
                <router-link to="/manage">后台管理</router-link>
              </el-dropdown-item>
              <el-dropdown-item style="font-size: 14px; padding: 5px 0">
                <router-link to="/front/password">修改密码</router-link>
              </el-dropdown-item>
              <el-dropdown-item style="font-size: 14px; padding: 5px 0">
                <router-link to="/front/person">个人信息</router-link>
              </el-dropdown-item>
              <el-dropdown-item style="font-size: 14px; padding: 5px 0">
                <span style="text-decoration: none" @click="logout">退出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose">
      <el-table
          :data="msg" style="width: 100%">
        <el-table-column prop="createTime" label="时间" width="140">
        </el-table-column>
        <el-table-column prop="messageType" label="类型" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.messageType==1" :key="tag">论坛消息</el-tag>
            <el-tag v-if="scope.row.messageType==2" :key="tag">系统消息</el-tag>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip="true" prop="content" label="内容"></el-table-column>
        <el-table-column prop="address" width="100" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="readMark(scope.row)" v-if="scope.row.readMark==0" slot="reference"> 标为已读
            </el-button>
          </template>

        </el-table-column>
      </el-table>

      <div style="padding: 10px 0">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[2, 5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-drawer>

    <el-dialog title="公告" :visible.sync="dialogFormVisible" width="40%" :close-on-click-modal="false">
      <el-descriptions v-for="o in notice" :key="o" :title="`${o.name}`" size="mini" column="1" :colon="false">
        <template slot="extra">
          <span>{{ o.time }}</span>
        </template>
        <el-descriptions-item label="">{{ o.content }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <div style="width: auto; margin: 0 auto">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Front",
  data() {
    return {
      drawer: false,
      msg: [], total: 0,
      pageNum: 1,
      pageSize: 5,
      newId: null,
      isFirst: true,
      notice: [],
      dialogFormVisible: false,
      isRequest: false,
      direction: 'ltr',
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.getMsg();

    // 加载公告
    this.request.get("/notice/front").then(res => {
      this.notice = res.data
    })
  },
  methods: {
    logout() {
      this.user = {};
      localStorage.removeItem("user");
      localStorage.removeItem("menus");
      this.$store.commit("logout")
      this.$message.success("退出成功")
    },
    getMsg() {
      // 循环迭代消息
      setInterval(() => {
        this.load();
      }, 3000)
    },
    loadMsg() {
      this.request.get("/msg/my", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        }
      }).then(res => {
        this.msg = res.data.records
        this.total = res.data.total
      })
    },
    load() {
      this.request.get("/user/check").then(res => {
        if (res.code !== "-1") {
          this.isRequest = true;
          if (this.isRequest && this.isFirst) {
            this.loadMsg();
            this.isFirst = false;
          }
        } else {
          this.isRequest = false;
        }
        if (this.isRequest) {
          this.request.get("/msg/sendMsg", {
            params: {
              pageNum: this.pageNum,
              pageSize: this.pageSize,
            }
          }).then(res => {
            if (res.data && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                this.$notify({
                  title: '新的消息通知',
                  type: 'success',
                  message: res.data[i].content
                });
              }
            }

          })
        }
      })
    },
    readMark(record) {
      record.readMark = 1;
      this.request.post("/msg", record).then(res => {
        if (res.code === '200') {
          this.$message.success("成功")
          this.loadMsg()
        } else {
          this.$message.error("失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.loadMsg()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.loadMsg()
    },
    handleClose(done) {
      done();
    }
  }
}
</script>

<style>
.item {
  display: inline-block;
  width: 100px;

  text-align: center;
  cursor: pointer
}

.item a {
  color: #1E90FF;
}

.item:hover {
  background-color: LightPink;
}
</style>

